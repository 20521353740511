// src/views/Home.tsx
import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './Home.module.scss';

const Home = () => {

  interface OptionType {
    value: string;
    label: string;
    description: string;
  }

  const [input, setInput] = useState('');
  const [transformedText, setTransformedText] = useState('');
  const [currentOption, setCurrentOption] = useState('titleCase');

  type TransformationFunction = (str: string) => string;

  const stringTransformations: Record<string, TransformationFunction> = {
    titleCase: (str) => str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()),
    sentenceCase: (str) =>
      str
        .toLowerCase()
        .replace(/(^\s*\w|[.!?]\s*\w)/g, (match) => match.toUpperCase()),
    upperCase: (str) => str.toUpperCase(),
    lowerCase: (str) => str.toLowerCase(),
    toggleCase: (str) => str.replace(/./g, (letter) => (letter.toUpperCase() === letter ? letter.toLowerCase() : letter.toUpperCase())),
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    setTransformedText(stringTransformations[currentOption](e.target.value));
  };

  const options: OptionType[] = [
    {
      value: 'titleCase',
      label: 'Title Case',
      description: 'In title case, the first letter of each word is capitalized, and the remaining letters are lowercase.',
    },
    {
      value: 'sentenceCase',
      label: 'Sentence Case',
      description: 'In sentence case, the first letter of the first word is capitalized, and the remaining letters are lowercase.',
    },
    {
      value: 'upperCase',
      label: 'Upper Case',
      description: 'In upper case, all letters are capitalized.',
    },
    {
      value: 'lowerCase',
      label: 'Lower Case',
      description: 'In lower case, all letters are lowercase.',
    },
    {
      value: 'toggleCase',
      label: 'Toggle Case',
      description: 'In toggle case, the case of each letter is switched from its current state.',
    },
  ];

  const descriptionRefs = useRef<Array<HTMLDivElement | null>>([]);

  const updateDescriptionOutline = () => {
    descriptionRefs.current.forEach((desc, idx) => {
      if (desc) {
        desc.style.border = options[idx].value === currentOption ? '2px solid #333' : 'none';
        desc.style.order = options[idx].value === currentOption ? '0' : '1';
      }
    });
  };

  const handleOptionClick = (option: string) => {
    setCurrentOption(option);
    updateDescriptionOutline();
  };

  const sortedOptions = useMemo(() => {
    const tempOptions = [...options];
    tempOptions.sort((a, b) => {
      if (a.value === currentOption) return -1;
      if (b.value === currentOption) return 1;
      return 0;
    });
    return tempOptions;
  }, [currentOption]);

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = transformedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  useEffect(() => {
    setTransformedText(stringTransformations[currentOption](input));
  }, [currentOption, input]);

  return (
    <div className={styles.Home}>
      <div className={styles.options}>
        {options.map((option) => (
          <button
            key={option.value}
            className={`${styles.option} ${currentOption === option.value ? styles.selected : ''}`}
            onClick={() => handleOptionClick(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <textarea
        className={styles.textInput}
        value={input}
        onChange={handleInputChange}
        rows={3}
      />
      <div className={styles.output}>
        <div className={styles.transformedText}>{transformedText}</div>
        <button className={styles.copyButton} onClick={copyToClipboard}>
          Copy to Clipboard
        </button>
      </div>
      <div className={styles.descriptionSection}>
        {sortedOptions.map((option: OptionType, index: number) => (
          <div
            key={option.value}
            ref={(el) => (descriptionRefs.current[index] = el)}
            className={`${styles.description} ${
              currentOption === option.value ? styles.selectedDescription : ''
              }`}
          >
            <h3>{option.label}</h3>
            <p>{option.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
