// src/components/Footer.tsx
import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.content}>
        <p>&copy; {new Date().getFullYear()} Capitalize My Title. All rights reserved.</p>
        {/* <p>
          Contact us: <a href="mailto:support@capitalizemytitle.net">support@capitalizemytitle.net</a>
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
