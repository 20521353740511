// src/views/About.tsx
import React from 'react';
import styles from './About.module.scss';

const About = () => {
  return (
    <div className={styles.About}>
      <h1>About Capitalize My Title</h1>
      <p>Welcome to Capitalize My Title, an intuitive and powerful text transformation tool designed to make your life easier when it comes to editing and formatting text. Whether you're writing an article, preparing a presentation, or working on social media posts, Capitalize My Title is here to help you transform your text into the perfect format.</p>

      <h2>Why Use Capitalize My Title?</h2>
      <p>When writing content, it's essential to maintain a consistent and professional style throughout your work. Capitalize My Title takes the guesswork out of formatting text by providing a simple and user-friendly interface to convert your text into the desired case style. With a click of a button, you can transform your text and make it look polished and professional.</p>

      <h3>Use Cases</h3>
      <ol>
        <li>Bloggers and content writers: Ensure consistent formatting throughout your articles and blog posts.</li>
        <li>Students and researchers: Format citations and references in the correct case style.</li>
        <li>Social media managers: Maintain a consistent style across all your social media posts.</li>
        <li>Business professionals: Prepare polished presentations and reports with properly formatted text.</li>
      </ol>

      <h4>Features</h4>
      <p>Capitalize My Title currently supports five different case styles:</p>
      <ul>
        <li>Title Case: Capitalizes the first letter of each word, with the remaining letters in lowercase.</li>
        <li>Sentence Case: Capitalizes the first letter of the first word in each sentence, with the remaining letters in lowercase.</li>
        <li>Upper Case: Capitalizes all letters.</li>
        <li>Lower Case: Converts all letters to lowercase.</li>
        <li>Toggle Case: Switches the case of each letter (uppercase to lowercase and vice versa).</li>
      </ul>

      <h5>Future Development</h5>
      <p>We're constantly working on improving Capitalize My Title and expanding its functionality. In the future, we plan to introduce additional features, such as:</p>
      <ol>
        <li>Custom case styles: Define your own case transformation rules.</li>
        <li>Bulk text transformation: Process multiple text files at once.</li>
        <li>Integration with popular writing platforms: Seamlessly use Capitalize My Title within your favorite writing tools.</li>
        <li>Language support: Transform text in multiple languages, with localization for specific regional formatting rules.</li>
      </ol>
      <p>We're committed to making Capitalize My Title the go-to solution for all your text transformation needs. Your feedback is invaluable to us, so please don't hesitate to reach out and share your thoughts, suggestions, and experiences.</p>
      <p>Happy writing!</p>
    </div>
  );
};

export default About;
